import "./style.scss";
// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";

import { Button_Style, Button_Type } from "../Button/Button";
import React, { useContext, useEffect, useRef, useState } from "react";

import Button from "../Button";
import { FileType } from "../../types/types";
import { FolderContext } from "../../contexts/FoldersContext";
import Hug from "../Hug";
import { ReactComponent as IconDelete } from "../../assets/img/icons/icon_Trash.svg";
import { ReactComponent as IconDownload } from "../../assets/img/icons/icon_Download.svg";
import { ReactComponent as IconFile } from "../../assets/img/icons/icon_File.svg";
import { ReactComponent as IconMore } from "../../assets/img/icons/icon_more.svg";
import Modal from "../Modal";
import ModalApplyfilter from "../ModalApplyfilter";
import PDFCover from "../PDFCover";
import { UserContext } from "../../contexts/UserContext";
import apiInstance from "../../axiosConfig";
import { toast } from "react-toastify";

const File = ({ file, isEditable }: { file: FileType; isEditable?: boolean }) => {
    const { user } = useContext(UserContext);
    const { tryRemoveFile } = useContext(FolderContext);
    const type = user?.type;
    const [folderId, setFolderId] = useState<string>("");
    const [clientNumber, setClientNumber] = useState<string>("");
    const [path, setPath] = useState<string>("");
    const [seeMore, setSeeMore] = useState<boolean>(false);
    const defaultPath = "";
    const isPath = typeof file === "string";
    const baseUrl = apiInstance.getUri();
    const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
    const [confirmModalFilterIsOpen, setConfirmModalFilterIsOpen] = useState(false);
    const fileExtension = path.split(".").pop();
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const canvas: HTMLCanvasElement | null = canvasRef.current;
    useEffect(() => {
        document.addEventListener("click", () => {
            setSeeMore(false);
        });
        return () => {
            document.removeEventListener("click", () => {
                setSeeMore(false);
            });
        };
    }, []);

    useEffect(() => {
        if (!file) return;
        if (isPath) {
            setPath(file);
        } else {
            const { path } = file as FileType;

            if (path) {
                const splitPath = path.split("/");
                setClientNumber(splitPath[0]);
                setFolderId(splitPath[splitPath.length - 2]);
            }
            const splitPathBlob = path.split(":");
            if (splitPathBlob[0] === "blob") {
                setPath(path);
            } else {
                setPath(`${baseUrl}/client/getFile/${path}` || defaultPath);
            }
        }
    }, [file, type, clientNumber, folderId]);

    const openFile = () => {
        if (isPath) {
            window.open(file, "_blank");
        } else {
            const splitPathBlob = file.path.split(":");
            if (splitPathBlob[0] === "blob") {
                window.open(file.path, "_blank");
            } else {
                window.open(`${baseUrl}/client/getFile/${file.path}`, "_blank");
            }
        }
    };

    const openMore = (event: React.MouseEvent) => {
        event.stopPropagation();
        setSeeMore(!seeMore);
    };

    const handleRemoveFile = async () => {
        setSeeMore(!seeMore);
        setConfirmModalIsOpen(false);
        if (!isPath) {
            if (typeof file !== "string") {
                const response = await tryRemoveFile(Number(folderId), file.id);
                if (response) {
                    toast.info("Votre fichier a bien été supprimé", {
                        position: "top-center",
                    });
                } else {
                    toast.error("Il y a eu un problème", {
                        position: "top-center",
                    });
                }
            }
        }
    };

    const handleDownload = async () => {
        setSeeMore(!seeMore);
        if (!isPath) {
            const response = await fetch(`${baseUrl}/client/getfile/${file.path}`);
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.setAttribute("href", url);
            a.setAttribute("download", file.name);
            a.click();
        }
    };

    const arrayButton = [
        {
            className: "info",
            label: "Télécharger",
            icon: (
                <IconDownload
                    width={16}
                    height={16}
                    fill="#1956A8"
                />
            ),
            onClick: handleDownload,
            isEditable: true,
        },
        {
            className: "info",
            label: "Ajouter le filtre ID Protect",
            icon: (
                <IconFile
                    width={16}
                    height={16}
                    fill="#1956A8"
                />
            ),
            onClick: () => setConfirmModalFilterIsOpen(!confirmModalFilterIsOpen),
            isEditable: isEditable,
        },
        {
            className: "error",
            label: "Supprimer",
            icon: (
                <IconDelete
                    width={16}
                    height={16}
                    fill="#FF5326"
                />
            ),
            onClick: () => setConfirmModalIsOpen(!confirmModalIsOpen),
            isEditable: isEditable,
        },
    ];

    return (
        <div className={`File pointer`}>
            {/* <span className={`tag ${document.receivedAt ? 'tag-success' : 'tag-info'}`} >
        {document.receivedAt ? document.receivedAt : 'En attente'}
      </span> */}
            <div
                className="File__thumbnail"
                onClick={openFile}>
                {(!isPath && file?.path) || isPath ? (
                    <>
                        {fileExtension === "pdf" ? (
                            <PDFCover
                                fileUrl={path}
                                pageIndex={0}
                                width={97}
                            />
                        ) : fileExtension === "doc" ? (
                            <div className="File__thumbnail__doc">
                                <IconFile fill="#A3BBDC" />
                                <span className="File__thumbnail__doc__title">.{fileExtension}</span>
                            </div>
                        ) : (
                            <img
                                src={path}
                                alt="file"
                            />
                        )}
                        <canvas
                            className="d-none"
                            ref={canvasRef}
                        />
                    </>
                ) : (
                    <div className="File__thumbnail__placeholder">
                        <IconFile fill="#A3BBDC" />
                    </div>
                )}
            </div>
            {seeMore && (
                <Hug
                    className="File_hug"
                    arrayButton={arrayButton}
                />
            )}
            <Button
                className="File_more"
                icon={<IconMore />}
                type={Button_Type.PRIMARY}
                style={Button_Style.SOLID}
                size="more"
                onClick={(e) => openMore(e)}
            />
            {!isPath && <span className="File__title">{file.name}</span>}
            {confirmModalIsOpen && (
                <Modal
                    title="Supprimer un fichier"
                    type="error"
                    labelOnConfirm="Supprimer"
                    isOpen={confirmModalIsOpen}
                    onClose={(e) => {
                        e.stopPropagation();
                        setConfirmModalIsOpen(false);
                    }}
                    onConfirm={async () => handleRemoveFile()}>
                    <p>Êtes vous sûr de vouloir supprimer ce fichier ? La suppression est définitive.</p>
                </Modal>
            )}

            {!isPath && (
                <ModalApplyfilter
                    isOpen={confirmModalFilterIsOpen}
                    onClose={() => setConfirmModalFilterIsOpen(false)}
                    canvas={canvas}
                    folderId={folderId}
                    file={file}
                />
            )}
        </div>
    );
};

export default React.memo(File);
