import "./style.scss";

import React, { FC, useEffect, useRef, useState } from "react";

import countryCode from "../../assets/data/CountryCodes.json";

const SelectCountry: FC<{ country: string; field: any }> = ({ field: { name, value, onChange, onBlur } }) => {
    const selectRef = useRef<HTMLDivElement>(null);
    const [isOpened, setIsOpened] = useState(false);
    const options = countryCode.map(({ code, name }, index) => (
        <span
            className="custom-option"
            data-value={name}
            key={index}
            onClick={() => handleOptionSelect(name)}>
            <img
                src={`${process.env.PUBLIC_URL}/img/flags/${code.toLowerCase()}.png`}
                alt={name}
            />
            {name}
        </span>
    ));

    const handleOptionSelect = (optionValue: string) => {
        setIsOpened(false);
        onChange({
            target: {
                name,
                value: optionValue,
            },
        });
    };

    const handleSelectToggle = () => {
        setIsOpened(!isOpened);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
            setIsOpened(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div
            className="SelectCountry select-wrapper"
            ref={selectRef}>
            <select
                className="select"
                value={value}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
            />
            <div className={`custom-select ${isOpened ? "opened" : ""}`}>
                <div
                    className="custom-select__trigger"
                    onClick={handleSelectToggle}>
                    <span className="d-flex align-items-center">
                        {value && (
                            <img
                                src={`${process.env.PUBLIC_URL}/img/flags/${countryCode
                                    .find((c) => c.name === value)
                                    ?.code.toLowerCase()}.png`}
                                alt={name}
                            />
                        )}
                        {value || "Pays émetteur"}
                    </span>
                </div>
                <div className="custom-options">{options}</div>
            </div>
        </div>
    );
};

export default SelectCountry;
