import "./style.scss";

import React, { FC, useRef, useState } from "react";

import countryCode from "../../assets/data/CountryCodes.json";

type IIndicatifPhoneProps = {
    setPhonePrefix: (prefix: string) => void;
    value: string;
};

const IndicatifPhone: FC<IIndicatifPhoneProps> = ({ setPhonePrefix, value }) => {
    const selectRef = useRef<HTMLDivElement>(null);
    const [isOpened, setIsOpened] = useState(false);
    const options = countryCode.map(({ dial_code, code, name }, index) => (
        <span
            className="custom-option"
            data-value={dial_code}
            key={index}
            onClick={() => handleOptionSelect(dial_code)}>
            <img
                src={`${process.env.PUBLIC_URL}/img/flags/${code.toLowerCase()}.png`}
                alt={name}
            />
            {name}: ({dial_code})
        </span>
    ));

    const handleOptionSelect = (optionValue: string) => {
        setIsOpened(false);
        setPhonePrefix(optionValue);
    };

    const handleSelectToggle = () => {
        setIsOpened(!isOpened);
    };

    return (
        <div
            className="IndicatifPhone select-wrapper"
            ref={selectRef}>
            <select className="select" />
            <div className={`custom-select ${isOpened ? "opened" : ""}`}>
                <div
                    className="custom-select__trigger"
                    onClick={handleSelectToggle}>
                    <span>{value}</span>
                </div>
                <div className="custom-options">{options}</div>
            </div>
        </div>
    );
};

export default IndicatifPhone;
