import "./assets/scss/style.scss";

import { FolderContext, FolderContextProvider } from "./contexts/FoldersContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { UserContext, UserContextProvider } from "./contexts/UserContext";
import { useContext, useEffect } from "react";

import { BrowserRouter } from "react-router-dom";
import ConditionalRoutes from "./ConditionalRoutes";
import { DocumentsContextProvider } from "./contexts/DocumentsContext";
import { Worker } from "@react-pdf-viewer/core";

const queryClient = new QueryClient();
function App() {
    const { userType } = useContext(UserContext);

    useEffect(() => {
        document.body.classList.add(userType ? userType : "individual");
    }, [userType]);

    useEffect(() => {
        let scrollTimeout: NodeJS.Timeout;
        const handleScroll = () => {
            document.body.classList.add("active");
            clearTimeout(scrollTimeout);
            scrollTimeout = setTimeout(() => {
                document.body.classList.remove("active");
            }, 1000);
        };
        window.addEventListener("scroll", handleScroll, true);
        return () => {
            window.removeEventListener("scroll", handleScroll, true);
        };
    }, []);

    return (
        <QueryClientProvider client={queryClient}>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <BrowserRouter>
                    <UserContextProvider>
                        <DocumentsContextProvider>
                            <FolderContextProvider>
                                <ConditionalRoutes />
                            </FolderContextProvider>
                        </DocumentsContextProvider>
                    </UserContextProvider>
                </BrowserRouter>
            </Worker>
        </QueryClientProvider>
    );
}

export default App;
