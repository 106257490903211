import "./style.scss";

import { FC } from "react";
import { ReactComponent as IconDelete } from "../../assets/img/icons/icon_Trash.svg";
import { ReactComponent as IconDownload } from "../../assets/img/icons/icon_Download.svg";
import { ReactComponent as IconFile } from "../../assets/img/icons/icon_File.svg";
import { ReactComponent as IconShare } from "../../assets/img/icons/icon_share.svg";
import { ReactComponent as IconToggle } from "../../assets/img/icons/icon_toggle.svg";

type HugProps = {
    onShareClick?: () => void;
    onDownloadClick?: () => void;
    onSecretClick?: () => void;
    onDeleteClick?: () => void;
    onApplyFilter?: () => void;
    arrayButton?: Array<any>;
    className?: string;
} & Record<string, any>;

const Hug: FC<HugProps> = ({ arrayButton, className = "", onShareClick, onSecretClick, onDeleteClick, onDownloadClick, onApplyFilter }) => {
    const hugClass = `hug ${className}`;

    return (
        <div className={hugClass}>
            <ul className="hug__list">
                {!arrayButton ? (
                    <>
                        {onApplyFilter && (
                            <li
                                className="hug__list__item"
                                onClick={onApplyFilter}>
                                <IconFile
                                    width={16}
                                    height={16}
                                    fill="#1956A8"
                                />{" "}
                                Ajouter le filtre ID Protect
                            </li>
                        )}
                        {onShareClick && (
                            <li
                                className="hug__list__item"
                                onClick={onShareClick}>
                                <IconShare
                                    width={16}
                                    height={16}
                                    fill="#1956A8"
                                />{" "}
                                Partager
                            </li>
                        )}
                        <li
                            className="hug__list__item"
                            onClick={onDownloadClick}>
                            <IconDownload
                                width={16}
                                height={16}
                                fill="#1956A8"
                            />{" "}
                            Télécharger
                        </li>
                        {onSecretClick && (
                            <li
                                className="hug__list__item"
                                onClick={onSecretClick}>
                                <IconToggle
                                    width={16}
                                    height={16}
                                    fill="#1956A8"
                                />{" "}
                                Secret
                            </li>
                        )}
                        {onDeleteClick && (
                            <li
                                className="hug__list__item error"
                                onClick={onDeleteClick}>
                                <IconDelete
                                    width={16}
                                    height={16}
                                    fill="#FF5326"
                                />{" "}
                                Supprimer
                            </li>
                        )}
                    </>
                ) : (
                    <>
                        {arrayButton &&
                            arrayButton
                                ?.filter((item) => item?.isEditable === true)
                                .map((item, index) => (
                                    <li
                                        key={index}
                                        onClick={item.onClick}
                                        className={`hug__list__item ${item.className}`}>
                                        {item.icon}
                                        {item.label}
                                    </li>
                                ))}
                    </>
                )}
            </ul>
        </div>
    );
};

export default Hug;
