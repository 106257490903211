import "./style.scss";

import { FC, useContext, useState } from "react";

import Alert from "../Alert";
import { DocumentUnion } from "../../types/types";
import { DocumentsContext } from "../../contexts/DocumentsContext";
import Input from "../Input";
import Modal from "../Modal/Modal";
import { UserContext } from "../../contexts/UserContext";
import { checkPassword } from "../../api/auth";
import { toast } from "react-toastify";
import { useDeleteDocument } from "../../api/lost";

type DeleteDocumentModalProps = {
    isOpen: boolean;
    onClose: () => void;
    documents: DocumentUnion[];
    onSubmit?: (selectedRows: DocumentUnion[]) => void;
};

const DeleteDocumentModal: FC<DeleteDocumentModalProps> = ({ isOpen, onClose, documents, onSubmit }) => {
    const [password, setPassword] = useState("");
    const [isPasswordValid, setIsPasswordValid] = useState(true);
    const { user, userType } = useContext(UserContext);
    const { refreshDocuments } = useContext(DocumentsContext) as any;
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const { mutate: deleteDocument } = useDeleteDocument();

    const handlePasswordChange = async () => {
        const response = await checkPassword(user?.id, password, userType);
        setIsPasswordValid(response.data);
    };

    const handleClose = () => {
        onClose();
        setPassword("");
        setErrorMessage("");
        setSuccessMessage("");
        setIsPasswordValid(true);
    };

    const handleDelete = async () => {
        try {
            await handlePasswordChange();
            if (password && isPasswordValid) {
                const data: any = {
                    userType,
                    documents,
                };
                deleteDocument(data, {
                    onSuccess: () => {
                        onSubmit && onSubmit([]);
                        toast.success("Document(s) supprimé(s) avec succès.");
                        handleClose();
                    },
                    onError: (error: any) => {
                        if (error.response?.status === 500) {
                            console.error("Internal Server Error:", error.response.data);
                            setErrorMessage("Erreur de serveur interne");
                        } else {
                            console.error("An error occurred:", error);
                            setErrorMessage("Une erreur est survenue");
                        }
                    },
                    onSettled: () => {
                        refreshDocuments();
                    },
                });
            } else {
                setErrorMessage("Mot de passe incorrect");
            }
        } catch (error: any) {
            setIsPasswordValid(false);
            setErrorMessage(error.response.data.message);
        }
    };

    return (
        <>
            {isOpen && (
                <Modal
                    isOpen={isOpen}
                    onClose={onClose}
                    title={`Supprimer ${documents.length} document(s)`}
                    confirmButtonDisabled={!isPasswordValid || !password}
                    onConfirm={handleDelete}>
                    {errorMessage && isPasswordValid && <Alert type="error"> {errorMessage} </Alert>}
                    {successMessage && <Alert type="success"> {successMessage} </Alert>}
                    <p className="mb-1">Veuillez confirmer votre mot de passe.</p>
                    <Input
                        placeholder="Mot de passe"
                        type="password"
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value);
                            setIsPasswordValid(true);
                            setErrorMessage("");
                        }}
                        errorMessage={!isPasswordValid && password ? "Mot de passe incorrect" : ""}
                        className={!isPasswordValid && password ? "error" : ""}
                    />
                </Modal>
            )}
        </>
    );
};

export default DeleteDocumentModal;
