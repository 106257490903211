import {PaymentTypes} from "../../constants/pricings";
import axios from '../../axiosConfig';

// eslint-disable-next-line react-hooks/rules-of-hooks
 export async function getUserCurrentPayments (userType) {
    try {

        const url = `client/payment_logs'`

        const body = new FormData()
        body.append('type', userType)


        const response = await axios.post(url, new FormData(), {
            // withCredentials: true,
        });

        if (response) {
            console.log(response)
        }
    } catch (error) {
        console.error(error);
    }
};


export async function getStripeConfig(body) {
    const urlAPI = 'client/stripe-config';
    return await axios.post(urlAPI, body, {
        withCredentials: true, // cookies are included in cross-site requests.
    });
}

export async function createPaymentIntent(body) {

    try {

        const url = `client/payment_intent`
        return await axios.post(url, body, {
            withCredentials: true, // cookies are included in cross-site requests.
        })
    } catch (error) {
        console.error(error);
        return null
    }
}

export async function sendEmailAfterPayment(body, paymentType) {
    let urlAPI = '';

    switch (paymentType) {
        case PaymentTypes.CREDIT: {
            urlAPI = 'client/after-credit-payment';
            break;
        }
        case PaymentTypes.ACCOMAPGNEMENT:
            urlAPI = 'client/accompagnement-subscription';
            break;
        case PaymentTypes.ID_TRACKER:
            urlAPI = 'client/idtracker-subscription';
            break;
        default:
            console.error(`Unknown payment type: ${paymentType}`);
            return null;
    }
    return axios.post(urlAPI, body, {
        withCredentials: true, // cookies are included in cross-site requests.
    });
}


export async function sendErrorPayment(body, paymentType) {
    let urlAPI = '';

    switch (paymentType) {
        case PaymentTypes.CREDIT: {
            urlAPI = 'client/credit-error-payment';
            break;
        }
        case PaymentTypes.ACCOMAPGNEMENT:
            urlAPI = 'client/accompagnement-error';
            break;
        case PaymentTypes.ID_TRACKER:
            urlAPI = 'client/idtracker-subscription-error';
            break;
        default:
            console.error(`Unknown payment type: ${paymentType}`);
            return null;
    }
    return axios.post(urlAPI, body, {
        withCredentials: true, // cookies are included in cross-site requests.
    });
}

export async function createCustomer(body) {

    try {
        const url = `client/create_customer`
        return await axios.post(url, body, {
            withCredentials: true, // cookies are included in cross-site requests.
        })
    } catch (error) {
        console.error(error);
        return null
    }
}
export async function createSubscription(body) {

    try {
        const url = `client/create_subscription`
        return await axios.post(url, body, {
            withCredentials: true, // cookies are included in cross-site requests.
        })
    } catch (error) {
        console.error(error);
        return null
    }
}

export async function getPricesFromStripe(body) {

    try {
        const url = `client/stripe-prices-list`
        return await axios.post(url, body, {
            withCredentials: true, // cookies are included in cross-site requests.
        });
    } catch (error) {
        console.error(error);
        return null
    }
}
