import "./style.scss";

import CNI from "../../assets/img/CNI_2.svg";
import DocumentError from "../../assets/img/document_error.svg";
import DocumentWarning from "../../assets/img/document_warning.svg";
import { FC } from "react";
import Illustration from "../../assets/img/Illustration_documents.svg";
import Passport from "../../assets/img/Illustration_Passeport.svg";
import Payment from "../../assets/img/icons/payment/sideImage.svg";
import Permis from "../../assets/img/Permis.svg";
import TitreSejour from "../../assets/img/TitreSejour.svg";

type DocumentIllustrationProps = {
    type?: string;
};
const DocumentIllustration: FC<DocumentIllustrationProps> = ({ type }) => {
    const documentImages: { [key: string]: string } = {
        "Carte d'identité": CNI,
        Passeport: Passport,
        "Permis de conduire": Permis,
        "Titre de séjour": TitreSejour,
        id: CNI,
        id_card: CNI,
        passeport: Passport,
        passport: Passport,
        permis: Permis,
        driver_permit: Permis,
        sejour: TitreSejour,
        residency_permit: TitreSejour,
        payment: Payment,
        error: DocumentError,
        warning: DocumentWarning,
    };

    const getDocumentImage = (type?: string) => {
        return type ? documentImages[type] : Illustration;
    };

    return (
        <img
            src={getDocumentImage(type)}
            alt={type}
            style={{ width: "100%" }}
        />
    );
};

export default DocumentIllustration;
