import "./style.scss";

import { FC, useEffect, useRef, useState } from "react";

import { Options } from "../../types/types";

type SelectProps = {
    placeholder?: string;
    value?: string;
    options: Options[];
    required?: boolean;
    className?: string;
    handleChange?: (value: string) => void;
    field?: any;
    errorMessage?: string;
} & Record<string, any>;

const Select: FC<SelectProps> = ({ options, placeholder, className, value, handleChange, field, errorMessage, ...rest }) => {
    // Include handleChange in the props
    const [isOpened, setIsOpened] = useState(false);

    const selectRef = useRef<HTMLDivElement>(null);

    const handleSelectToggle = () => {
        setIsOpened(!isOpened);
    };

    const handleOptionSelect = (optionValue: string) => {
        setIsOpened(false);
        if (!field && handleChange) {
            handleChange(optionValue);
        }
        if (field && field.onChange) {
            field.onChange({
                target: {
                    name: field.name,
                    value: optionValue,
                },
            });
            handleChange && handleChange(optionValue);
        }
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
            setIsOpened(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div
            className={`select-wrapper ${className} ${errorMessage ? "error" : ""}`}
            ref={selectRef}>
            <select
                className={`select`}
                {...rest}
            />
            <div className={`custom-select ${isOpened ? "opened" : ""}`}>
                <div
                    className={`custom-select__trigger ${className}`}
                    onClick={handleSelectToggle}>
                    <span className={value && "isFiled"}>
                        {field && field?.value !== ""
                            ? options.find((opt) => opt?.value === field?.value)?.label
                            : value
                            ? options.find((opt) => opt?.value === value)?.label
                            : placeholder}
                    </span>
                </div>
                {
                    <div className="custom-options">
                        {options.map((option, index) => (
                            <span
                                className="custom-option"
                                key={index}
                                data-value={option}
                                onClick={() => handleOptionSelect(option.value)}>
                                {option.label}
                            </span>
                        ))}
                    </div>
                }
            </div>
            {errorMessage && <span className="error-message">{errorMessage}</span>}
        </div>
    );
};

export default Select;
