import "./style.scss";

import { DocumentBasylic, DocumentUnion } from "../../types/types";
import { FC, useContext, useState } from "react";

import Alert from "../Alert";
import { DocumentsContext } from "../../contexts/DocumentsContext";
import FileUploader from "../FileUploader";
import Input from "../Input";
import Modal from "../Modal";
import Select from "../Select";
import { UserContext } from "../../contexts/UserContext";
import { checkPassword } from "../../api/auth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSaveLostDocument } from "../../api/lost";

type LostDocumentModalProps = {
    isOpen: boolean;
    onClose: () => void;
    documents: DocumentUnion[] | DocumentBasylic[];
    onSubmit?: (selectedRows: DocumentUnion[]) => void;
    isInitialLost?: boolean;
};

const options = [
    {
        value: "SUSPICION_DE_FUITE",
        label: "Suspicion de fuite",
    },
    {
        value: "PERDU",
        label: "Perdu",
    },
    {
        value: "VOLE",
        label: "Volé",
    },
    {
        value: "USURPE",
        label: "Usurpé",
    },
];

const LostDocumentModal: FC<LostDocumentModalProps> = ({ isOpen, onClose, documents, onSubmit, isInitialLost }) => {
    const { mutate: saveLostDocument } = useSaveLostDocument();
    const navigate = useNavigate();
    const [pv, setPv] = useState<File[]>([]);
    const [password, setPassword] = useState("");
    const [causeOpposition, setCauseOpposition] = useState("");
    const [isPasswordValid, setIsPasswordValid] = useState(true);
    const { refreshDocuments } = useContext(DocumentsContext) as any;
    const [errorMessage, setErrorMessage] = useState("");
    const { user } = useContext(UserContext);
    const type = user?.type;

    const handlePasswordChange = async () => {
        try {
            const response = await checkPassword(user?.id, password, type);
            setIsPasswordValid(response.data);
        } catch (error) {
            console.error("Erreur serveur");
        }
    };

    const handleChangeFiles = (files: File[]) => {
        setPv((prevSelectedFiles) => [...prevSelectedFiles, ...files]);
    };

    const handleFileRemoval = (file: File) => {
        const updatedFiles = pv.filter((selectedFile) => selectedFile !== file);
        setPv(updatedFiles);
    };

    const handleSaveLostDocument = (document: DocumentUnion | DocumentBasylic, file: File, status: string) => {
        const data: any = {
            type,
            document,
            file,
            status,
        };
        saveLostDocument(data, {
            onSuccess: () => {
                onSubmit && onSubmit([]);
                refreshDocuments();
                toast.success("Document(s) mis(s) en opposition avec succès.");
                if (isInitialLost) {
                    navigate("/particuliers/dashboard");
                }
            },
            onError: (error: any) => {
                setErrorMessage(error.response.data);
            },
        });
    };

    const handleLost = async () => {
        try {
            await handlePasswordChange();
            if (password && isPasswordValid) {
                handleSaveLostDocument(documents[0], pv[0], causeOpposition);
            } else {
                setErrorMessage("Mot de passe incorrect");
            }
        } catch (error: any) {
            setIsPasswordValid(false);
            setErrorMessage(error.response.data.message);
        }
    };

    return (
        <>
            {isOpen && (
                <Modal
                    labelOnConfirm="Déclarer comme perdu"
                    isOpen={isOpen}
                    onClose={onClose}
                    title={`Déclarer ${documents.length} document(s) comme perdu(s)`}
                    confirmButtonDisabled={!isPasswordValid || !password}
                    onConfirm={handleLost}>
                    {errorMessage && isPasswordValid && <Alert type="error"> {errorMessage} </Alert>}

                    <p className="mb-1">Pour déclarer votre document comme perdu, veuillez nous transmettre votre procès verbal.</p>
                    <FileUploader
                        onChange={handleChangeFiles}
                        onDelete={handleFileRemoval}
                        title="Recto"
                        fileLimit={1}
                        maxFileSize={2}
                        allowedFormats={"image/jpeg, image/png, application/pdf"}
                    />
                    <Select
                        label="Cause de l'opposition"
                        placeholder="Choisir une cause de l' opposition"
                        options={options}
                        handleChange={(value) => {
                            setCauseOpposition(value);
                        }}
                        value={causeOpposition}
                        className="mb-1"
                    />
                    <Input
                        placeholder="Mot de passe"
                        type="password"
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value);
                            setIsPasswordValid(true);
                            setErrorMessage("");
                        }}
                        errorMessage={!isPasswordValid && password ? "Mot de passe incorrect" : ""}
                        className={!isPasswordValid && password ? "error" : ""}
                    />
                </Modal>
            )}
        </>
    );
};

export default LostDocumentModal;
