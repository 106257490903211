import axios from '../../axiosConfig';

export async function register(user) {
    const response = await axios.post("/client/register", {
      ...user,
      withCredentials: false,
    });
    return response.data;
}


export async function updateAccount(type,account) {
  try {
    const response = await axios.put('/client/account', {type, account});

    if (response) {
      console.log(response);
    }

    return response;
  } catch(e) {
    console.log(e);
    throw e; // Propager l'erreur pour que l'appelant puisse la gérer
  }
}

export async function forgotPassword (token, password) {
    const response = await axios.post('/client/password', {
      token,
      password
    })
	return response;
}

export async function resetPassword (oldPassword, password,type) {
	const response = await axios.post('/client/connected/password', {
		oldPassword,
		password,
	  type
    })
	return response;
}



export async function recordPosition(type, position) {
  try {
    const body = new FormData();
    body.append("position", JSON.stringify(position));
    body.append("type", type);
    const response = await axios.post("/client/position", body);
    return response;
  } catch (error) {
    return error;
  }
}

export async function resend(email, action) {
    const data = {
      email,
      action
    }
    const response = await axios.post("client/resend", data);
    return response;
}

export async function codeSecurity({ email }) {
  try {
    const response = await axios.post("/client/send_security_code", {
      email,
    });
    return response;
  } catch (error) {
    return error;
  }
}

// export async function resend ({ email }) {
//   try {
//     const response = await this.$axios.$post('/api/client/resend', {
//       email,
//       action: 'p' // v for verify_account, to determine
//     })
//     if (response) {
//       return response
//     }
//   } catch (error) {
//     return error;
//   }
// }

export async function checkEmail(email) {
  try {
    const response = await axios.post("/client/check_email", email);
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function getOffers(userType) {
	const response = await axios.post("/client/offres",{ type: userType });
	return response;
}

export async function getSubscription(type, email) {
	const response = await axios.post("/client/offresPurchase",{ type,email});
	return response;
}

export async function buyOffer(email,idOffer) {
	const response = await axios.post("/client/cerateNewOffres",
		{ email: email, idOffer: idOffer }
	);
	return response;
}

export async function deleteAccount(type) {
	const response = await axios.post("/client/accountDelete",{type});
	return response;
}

export async function getCredits(type,email) {
	const response = await axios.post("/client/credit",{ type,email });
	return response;
}

export async function updateProfilePicture(type,filePicture) {

	const body = new FormData();
	body.append("type",type);
	body.append("filePicture",filePicture);
	const response = await axios.post("/client/account/updatePicture",body);
	return response;
}

export async function deleteProfilePicture(type) {
	const body = new FormData();
    body.append("type", type);
	const response = await axios.put("/client/account/deletePicture",body);
	return response;
}
