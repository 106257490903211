import DocumentIllustration from "../components/DocumentIllustration";
import VerifyDatabaseDocument from "../components/VerifyDatabaseDocument";
import { useState } from "react";

export default function DatabaseDocuments() {
    const [type, setType] = useState<string>("");

    const handleChangeType = (type: string) => {
        setType(type);
    };
    return (
        <div className="content__body h-100">
            <div className="row h-100 align-items-center">
                <div className="col-sm-6">
                    <div className="card h-100">
                        <VerifyDatabaseDocument
                            type={type}
                            setType={handleChangeType}
                        />
                    </div>
                </div>
                <div className="col-sm-6 d-flex justify-content-center align-items-center">
                    <DocumentIllustration type={type} />
                </div>
            </div>
        </div>
    );
}
