import "./style.scss";

import { DocumentBasylic, DocumentStatusType, DocumentUnion } from "../../types/types";
import { FC, useContext, useEffect, useState } from "react";

import Alert from "../Alert";
import Button from "../Button";
import { Button_Type } from "../Button/Button";
import DocumentPreview from "../DocumentPreview";
import DocumentStatus from "../DocumentStatus";
import { DocumentsContext } from "../../contexts/DocumentsContext";
import Loader from "../Loader";
import LostDocumentModal from "../LostDocumentModal/LostDocumentModal";
import Skeleton from "../Skeleton";
import { UserContext } from "../../contexts/UserContext";
import { addFollowingDocument } from "../../api/followed";
import { useNavigate } from "react-router-dom";

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
type AddDocumentInfosProps = {
    isScanning: boolean;
    document?: DocumentBasylic;
};
const AddDocumentInfos: FC<AddDocumentInfosProps> = ({ isScanning, document }) => {
    const { user } = useContext(UserContext);
    const navigate = useNavigate();
    const [showModalLost, setShowModalLost] = useState(false);
    const [status, setStatus] = useState<DocumentStatusType>();
    const [errorMessage, setErrorMessage] = useState("");
    const { followed } = useContext(DocumentsContext);
    const [currentDocument, setCurrentDocument] = useState<DocumentUnion>();
    const { refreshDocuments } = useContext(DocumentsContext) as any;
    const type = user?.type;

    useEffect(() => {
        formatStatus();
        if (document) {
            handleAddDocument();
        }
    }, [document]);

    useEffect(() => {
        if (document) {
            getCurrentDocument();
        }
    }, [followed]);

    const formatStatus = async () => {
        if (document) {
            try {
                setStatus({
                    conformite: document.conformite,
                    darkWeb: document.darkWeb,
                });
            } catch (error: any) {
                console.log(error.response.data);
            }
        }
    };

    const getCurrentDocument = async () => {
        if (document) {
            const currentDocument = followed.find((item: any) => item.number === document?.numeroDocument);
            setCurrentDocument(currentDocument);
        }
    };

    const handleAddDocument = async () => {
        try {
            await addFollowingDocument(type, document);
            await refreshDocuments();
        } catch (error: any) {
            if (error.response?.status === 500) {
                console.error("Internal Server Error:", error.response.data);
                setErrorMessage("Erreur de serveur interne");
            } else if (error.response?.status === 409) {
                setErrorMessage(error.response.data.error);
            } else {
                console.error("An error occurred:", error);
                setErrorMessage("Une erreur est survenue");
            }
        }
    };

    return (
        <div className="AddDocumentInfos card h-100">
            {isScanning ? (
                <>
                    {isMobile && <Loader text="Analyse en cours. L'analyse peut prendre jusqu'à 30secondes." />}
                    <Skeleton
                        width="90%"
                        height={28}
                    />
                    <Skeleton
                        width="100%"
                        height={228}
                    />
                    <Skeleton
                        width="50%"
                        height={26}
                    />
                    <Skeleton
                        width="90%"
                        height={40}
                    />
                    <Skeleton
                        width="85%"
                        height={40}
                    />
                    <Skeleton
                        width="100%"
                        height={40}
                    />
                </>
            ) : (
                <>
                    {document && (
                        <>
                            <h3>Document enregistré</h3>
                            <DocumentPreview document={document} />
                            {status && (
                                <DocumentStatus
                                    status={status}
                                    documentType={document.typeDocument}
                                />
                            )}
                            {errorMessage ? (
                                <>
                                    <Alert type="error">{errorMessage}</Alert>
                                    <div className="AddDocumentInfos__btns">
                                        <Button
                                            label="Ajouter un autre document"
                                            onClick={() => window.location.reload()}
                                        />
                                    </div>
                                </>
                            ) : (
                                <div className="AddDocumentInfos__btns">
                                    <Button
                                        label="Déclarer perdu"
                                        type={Button_Type.Error}
                                        onClick={() => setShowModalLost(true)}
                                    />
                                    <Button
                                        label="Activer le suivi"
                                        onClick={() => navigate(`/particuliers/dashboard/`)}
                                    />
                                    {showModalLost && currentDocument && (
                                        <LostDocumentModal
                                            isInitialLost
                                            isOpen={showModalLost}
                                            onClose={() => setShowModalLost(false)}
                                            documents={[currentDocument]}
                                        />
                                    )}
                                </div>
                            )}
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default AddDocumentInfos;
