import "./style.scss";

import Button, { Button_Style, Button_Type } from "../Button/Button";
import { FC, useContext, useEffect } from "react";

import { DocumentBasylic } from "../../types/types";
import Loader from "../Loader";
import ShareID from "@shareid/onboarding_desktop";
import { UserContext } from "../../contexts/UserContext";
import { getVerificationResult } from "../../api/analysis";

type ShareIDVerificationProps = {
    accessToken: string;
    applicantId?: string;
    setIsScanning?: any;
    setIsScanned?: any;
    setErrorType?: any;
    setDocument?: any;
    errorType?: any;
    setIsShareID: any;
};

const ShareIDVerification: FC<ShareIDVerificationProps> = ({
    accessToken,
    applicantId,
    setIsScanning,
    setIsScanned,
    setErrorType,
    setDocument,
    errorType,
    setIsShareID,
}) => {
    const { user } = useContext(UserContext);
    const userType = user?.type;
    const formatDocument = (document: any) => {
        console.log(document);
        const { doc_num, surname, name, sex, expiration_date, issuance_date, mrz_1, mrz_2, birth_date, birth_place } =
            document.document.ocr;
        const documentFormatted: DocumentBasylic = {
            id: document.id,
            // TODO: vérifier la validité de ces valeurs
            conformite: true,
            darkWeb: false,
            country_code: document.document.type.country_code,
            dateDelivrance: issuance_date,
            dateExpiration: expiration_date,
            dateNaissance: birth_date,
            nom: name,
            nomUsage: surname,
            numeroDocument: doc_num,
            paysNaissance: birth_place,
            prenom: surname,
            sexe: sex,
            typeDocument: document.document.type.doc_type,
            mrz: mrz_1,
            viz: mrz_2,
        };
        return documentFormatted;
    };

    // add the class IDVerify__container to first div in the component ShareID

    const addClassToShareID = () => {
        const shareID = document.querySelector(".IDVerify");
        const container = shareID?.children[0];
        const footer = shareID?.children[1];
        const header = container?.children[0]?.children[0];

        header?.classList.add("IDVerify__header");
        header?.classList.remove("sdk-flex");

        container?.classList.add("IDVerify__container");
        container?.classList.remove("sdk-h-dvh", "md:sdk-pt-14", "sdk-pt-3", "sdk-px-3");
        container?.children[0]?.classList.remove("md:sdk-px-6", "sdk-px-3");

        footer?.classList.add("IDVerify__footer");
    };

    useEffect(() => {
        addClassToShareID();
    }, [accessToken]);

    const getDataFromUID = async () => {
        if (!applicantId) return console.log("uuid manquant");
        setIsScanning && setIsScanning(true);
        if (!userType) return;
        try {
            const response = await getVerificationResult(userType, applicantId);
            console.log(response.data);
            if (response.data.documentAnalyse.result === "rejected") {
                setIsScanned && setIsScanned(true);
                setIsScanning && setIsScanning(false);
                setErrorType && setErrorType({ status: 400, message: "Suspection de fraude" });
            } else if (response.status === 200) {
                setIsScanned && setIsScanned(true);
                setDocument && setDocument(formatDocument(response.data.documentAnalyse));
                setIsScanning && setIsScanning(false);
            }
        } catch (error: any) {
            console.error("Une erreur s'est produite :", error);
            if (error.response.status === 404 && errorType?.status !== 400) {
                setTimeout(() => getDataFromUID(), 5000);
            }
        }
    };
    return (
        <div className="IDVerify">
            {accessToken ? (
                <ShareID
                    debug={true}
                    token={accessToken}
                    language="fr"
                    onFail={(data: any) => {
                        console.log("verification failed : ", data);
                    }}
                    onSuccess={(data: any) => {
                        console.log("=============================");
                        console.log("onSuccess", data);
                        getDataFromUID();
                    }}
                    onExit={(data: any) => console.log("exit", data)}
                    theme={{
                        colors: {
                            primary: {
                                active: "#1956A8",
                                hover: "#0F3465",
                                disabled: "#9FC1EF",
                            },
                        },
                    }}
                    //redirectUrl="https://www.basylic.com"
                />
            ) : (
                <Loader />
            )}
            <div className="">
                <p className="mb-2 text-center">Vous n'avez pas le document d'identité sur vous ?</p>
                <Button
                    type={userType === "partner" ? Button_Type.SECONDARY : Button_Type.PRIMARY}
                    style={Button_Style.OUTLINED}
                    label="Utiliser un scan du document"
                    onClick={() => setIsShareID(false)}
                    className="m-auto"
                />
            </div>
        </div>
    );
};

export default ShareIDVerification;
