import "./style.scss";

import { DocumentStatusType } from "../../types/types";
import { FC } from "react";
import { ReactComponent as IconChecked } from "../../assets/img/icons/icon_status-Checked.svg";
import { ReactComponent as IconInfo } from "../../assets/img/icons/icon_Info.svg";
import { ReactComponent as IconWarning } from "../../assets/img/icons/icon_status-Warning.svg";
import Loader from "../Loader";

type DocumentStatusProps = {
    status: DocumentStatusType;
    documentType: string;
    isDarkWeb?: boolean;
};

const DocumentStatus: FC<DocumentStatusProps> = ({ status, isDarkWeb }) => {
    const statusBooleanMessages: {
        [K in keyof Omit<DocumentStatusType, "idTracker">]: (isTrue: boolean) => string;
    } = {
        conformite: (isTrue) =>
            isTrue
                ? "La conformité du document d’identité est validée par l’analyse"
                : "La conformité du document d’identité n'est pas validée par l’analyse",
        lost: (isTrue) =>
            isTrue
                ? "Ce document d’identité est déclaré perdu ou volé chez d’ID Protect"
                : "Ce document d’identité n’est pas déclaré perdu ou volé chez d’ID Protect",
        darkWeb: (isTrue) =>
            isTrue
                ? "Ce document a été trouvé sur des sites publics ou sur le DarkWeb"
                : "Ce document n’a pas été trouvé sur des sites publics ou sur le DarkWeb",
        locked: (isTrue) => (isTrue ? "Ce document d’identité est verrouillé" : "Ce document d’identité n’est pas verrouillé"),
    };

    const statusStringMessage: {
        [K in keyof Pick<DocumentStatusType, "idTracker">]: (value: string) => string;
    } = {
        idTracker: (value) => {
            switch (value) {
                case "requested":
                    return "En attente de la confirmation de l'utilisateur ...";
                case "confirm":
                    return "L'utilisateur a donné son accord pour l'utilisation de son document d'identité.";
                case "reject":
                    return "L'utilisateur a bloqué l'utilisation de son document d'identité.";
                default:
                    return "L'utilisateur n'a pas activé ID Tracker.";
            }
        },
    };

    const getStatusMessage = (key: keyof DocumentStatusType): string => {
        const value = status[key as keyof DocumentStatusType];
        if (key === "idTracker") {
            // Vérification que la fonction existe avant de l'invoquer
            const fn = statusStringMessage[key];
            if (typeof fn === "function") {
                return fn(value as string);
            }
        } else {
            // Pour les booléens, vérification similaire
            const fn = statusBooleanMessages[key];
            if (typeof fn === "function") {
                return fn(value as boolean);
            }
        }

        // Gestion du cas où la clé ne correspond à aucune fonction
        // ou si le type de clé est incorrect
        return "Statut inconnu ou clé non prise en charge.";
    };

    const getIcon = (key: keyof DocumentStatusType) => {
        switch (key) {
            case "conformite":
                return status[key] ? (
                    <IconChecked
                        fill="#17EB79"
                        width="1.5rem"
                    />
                ) : (
                    <IconWarning
                        fill="#FF5326"
                        width="1.5rem"
                    />
                );
            case "lost":
                return status[key] ? (
                    <IconWarning
                        fill="#FF5326"
                        width="1.5rem"
                    />
                ) : (
                    <IconChecked
                        fill="#17EB79"
                        width="1.5rem"
                    />
                );
            case "darkWeb":
                return status[key] ? (
                    <IconWarning
                        fill="#FF5326"
                        width="1.5rem"
                    />
                ) : (
                    <IconChecked
                        fill="#17EB79"
                        width="1.5rem"
                    />
                );
            case "locked":
                return status[key] ? (
                    <IconWarning
                        fill="#FF5326"
                        width="1.5rem"
                    />
                ) : (
                    <IconChecked
                        fill="#17EB79"
                        width="1.5rem"
                    />
                );
            case "idTracker":
                return status[key] === "requested" ? (
                    <Loader size="1.5rem" />
                ) : status[key] === "requested" ? (
                    <IconWarning
                        fill="#FF5326"
                        width="1.5rem"
                    />
                ) : status[key] === "confirm" ? (
                    <IconChecked
                        fill="#17EB79"
                        width="1.5rem"
                    />
                ) : status[key] === "notRequested" ? (
                    <IconInfo
                        fill="#1956A8"
                        width="1.5rem"
                    />
                ) : (
                    <IconWarning
                        fill="#FF5326"
                        width="1.5rem"
                    />
                );
            default:
                return (
                    <IconChecked
                        fill="#17EB79"
                        width="1.5rem"
                    />
                );
        }
    };

    return (
        <>
            <h5 className="DocumentDetails__body__title">Statut du document</h5>
            {isDarkWeb
                ? Object.keys(status)
                      .map((item: any, key) => (
                          <div
                              className="DocumentStatus DocumentStatus--darkWeb"
                              key={key}>
                              {getIcon(item)}
                              <span>{getStatusMessage(item)}</span>
                          </div>
                      ))
                      .filter((item: any, key) => key < 2)
                : Object.keys(status).map((item: any, key) => (
                      <div
                          className="DocumentStatus"
                          key={key}>
                          {getIcon(item)}
                          <span>{getStatusMessage(item)}</span>
                      </div>
                  ))}
        </>
    );
};

export default DocumentStatus;
