import { useMutation, useQuery, useQueryClient } from "react-query";

import axios from "../../axiosConfig";

export async function getLostDocuments(type) {
  try {
    const response = await axios.post('/client/get_lost_documents', { type });

    return response.data.results;
  } catch (error) {
    return error;
  }
}

// Hook principal pour récupérer les documents perdus
export function useLostDocuments(type) {
  return useQuery({
    queryKey: ['lostDocuments', type],
    queryFn: async () => {
      const response = await axios.post('/client/get_lost_documents', { type });
      return response.data.results;
    },
    // Options de configuration pour optimiser les performances
    staleTime: 5 * 60 * 1000, // Considérer les données comme fraîches pendant 5 minutes
	  cacheTime: 30 * 60 * 1000, // Garder en cache pendant 30 minutes
	enabled: type === "individual",
  });
}

export async function saveLostDocument(
type,
	document,
	file,
status
) {
	const url = '/client/save_lost_documents/from/followedDocument';
	const body = new FormData();
	body.append("type",type);
	body.append("number",document.number);
	body.append("pv",file);
	body.append("id",file);
	body.append("status",status);
	return axios.post(url,body);
}

// Hook pour sauvegarder un document perdu (à partir d'un document suivi)
export function useSaveLostDocument() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ type, document, file, status }) => {
      const body = new FormData();
      body.append("type", type);
      body.append("number", document.number);
      body.append("pv", file);
      body.append("id", file);
      body.append("status", status);
      return axios.post('/client/save_lost_documents/from/followedDocument', body);
    },
    // Optimisation du cache après une mutation réussie
    onSuccess: (_, variables) => {
      // Invalider le cache pour forcer un rafraîchissement
      queryClient.invalidateQueries(['lostDocuments', variables.type]);
    },
  });
}

export async function postLostDocument(
	type,
	document,
	file
) {
	console.log(document.numeroDocument, file)
		const url = '/client/save_lost_documents';
		const body = new FormData();
		body.append("type",type);
		body.append("idNumber",document.numeroDocument);
	body.append("docType",document.docType);
		body.append("pv",file);
		body.append("id",file);
		return axios.post(url,body);

}

// Hook pour poster un nouveau document perdu
export function usePostLostDocument() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ type, document, file }) => {
      const body = new FormData();
      body.append("type", type);
      body.append("idNumber", document.numeroDocument);
      body.append("docType", document.docType);
      body.append("pv", file);
      body.append("id", file);
      return axios.post('/client/save_lost_documents', body);
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(['lostDocuments', variables.type]);
    },
  });
}

export async function searchLostDocument(type, docType, idNumber) {
  try {
    const body = new FormData();
    body.append("type", type);
    body.append("docType", docType);
    body.append("number", idNumber);
    const response = await axios.post("/client/search_lost_document", body);
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
}

// Hook pour rechercher un document perdu
export function useSearchLostDocument() {
    return useMutation({
        mutationFn: async ({ type, docType, idNumber }) => {
            const body = new FormData();
            body.append("type", type);
            body.append("docType", docType);
            body.append("number", idNumber);
            const response = await axios.post("/client/search_lost_document", body);
            return response.data;
        },
    });
}

export function deleteDocument(userType, documents) {
  let data = {
    type: userType,
    documents
  }

	const url = '/client/deleteDocument';
	let body = data
	return axios.post(url,body);
}

// Hook pour supprimer un document
export function useDeleteDocument() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ userType, documents }) => {
      return axios.post('/client/deleteDocument', {
        type: userType,
        documents
      });
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(['lostDocuments', variables.userType]);
    },
  });
}

export function getDocumentByNumber(numeroDocument,type) {
  let data = {
	type,
	  numeroDocument
  }

	const url = '/client/getDocumentByNumber';
	let body = data
	return axios.post(url,body);
}

// Hook pour obtenir un document par numéro
export function useDocumentByNumber(numeroDocument, type) {
  return useQuery({
    queryKey: ['document', numeroDocument, type],
    queryFn: async () => {
      const response = await axios.post('/client/getDocumentByNumber', {
        type,
        numeroDocument
      });
      return response.data;
    },
    // Ne pas récupérer automatiquement les données
    enabled: !!numeroDocument && !!type,
  });
}
