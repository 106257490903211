import { useContext, useState } from "react";

import Alert from "../../Alert";
import Button from "../../Button";
import DocumentPreview from "../../DocumentPreview";
import { DocumentPreviewNumberType } from "../../../types/types";
import { ReactComponent as IconChecked } from "../../../assets/img/icons/icon_status-Checked.svg";
import { ReactComponent as IconInfo } from "../../../assets/img/icons/icon_Info.svg";
import { ReactComponent as IconWarning } from "../../../assets/img/icons/icon_status-Warning.svg";
import { UserContext } from "../../../contexts/UserContext";
import { documentRequest } from "../../../api/invitation";

type Props = {
    result: DocumentPreviewNumberType;
    refresh?: () => void;
};

export default function VerifyDatabaseDocumentResult({ result, refresh }: Props) {
    const { userType } = useContext(UserContext);
    const [requestId, setRequestId] = useState<string>("");
    const [requestSent, setRequestSent] = useState<boolean>(false);
    const idTracker = async () => {
        try {
            const response = await documentRequest(result?.numeroDocument, userType);
            setRequestId(response.data.id);
            setRequestSent(true);
        } catch (error: any) {
            console.log(error.response.data);
        }
    };
    return (
        <div className="d-flex flex-column align-items-end justify-content-between h-100">
            <div className="w-100">
                <DocumentPreview
                    document={result}
                    type={result.status}
                />
                {result?.darkWeb ? (
                    <div>
                        <Alert type="error">Soyez vigilant</Alert>
                        <div className="d-flex gap-05 align-items-center">
                            <IconWarning
                                fill="#FF5326"
                                width="1.5rem"
                                className="flex-none"
                            />
                            Le document est présent sur le dark web
                        </div>
                    </div>
                ) : (
                    <div className="d-flex gap-05 align-items-center">
                        <IconChecked
                            fill="#17EB79"
                            width="1.5rem"
                            className="flex-none"
                        />
                        Le document n'est pas présent sur le dark web
                    </div>
                )}
                {result.status === "not_lost_or_sloten" && (
                    <>
                        <div className="d-flex gap-05 align-items-center mb-2">
                            <IconChecked
                                fill="#17EB79"
                                width="1.5rem"
                                className="flex-none"
                            />
                            Document connu des services ID Protect, ne fait l’objet d’aucun signalement spécifique.
                        </div>
                        <div className="card card--info mb-2">
                            <div className="card-body">
                                <h5 className="card-title">ID Tracker</h5>
                                <p className="card-text mb-2">
                                    Vous pouvez avertir le titulaire que son identité a été utilisée pour accéder à vos services.
                                </p>
                                <Button
                                    label="Avertir"
                                    onClick={idTracker}
                                    disabled={requestSent}
                                />
                            </div>
                        </div>
                        {requestSent && <Alert type="success">Le mail a été envoyée avec succès</Alert>}
                    </>
                )}
                {result.status === "lost_or_sloten" && (
                    <>
                        <Alert type="error">Nous vous conseillons de ne pas entrer en relation sur présentation de ce document.</Alert>
                        <div className="d-flex gap-05 align-items-center">
                            <IconWarning
                                fill="#FF5326"
                                width="1.5rem"
                                className="flex-none"
                            />
                            Le document est déclaré perdu/volé
                        </div>
                    </>
                )}
                {result.status === "not_found" && (
                    <div className="d-flex gap-05 align-items-center">
                        <IconInfo
                            fill="#1956A8"
                            width="1.5rem"
                            className="flex-none"
                        />
                        Le document est absent de la base de données ID Protect
                    </div>
                )}
                {result.status === "locked_document" && (
                    <div className="d-flex gap-05 align-items-center">
                        <IconWarning
                            fill="#FF5326"
                            width="1.5rem"
                            className="flex-none"
                        />
                        Le document est bloqué par l'utilisateur
                    </div>
                )}
                {result.status === "fraude" && (
                    <>
                        <Alert type="error">Suspiscion de fraude</Alert>
                        <div className="d-flex gap-05 align-items-center">
                            <IconWarning
                                fill="#FF5326"
                                width="1.5rem"
                                className="flex-none"
                            />
                            Le document a fait l’objet d’un signalement pour usurpation d’identité
                        </div>
                    </>
                )}
            </div>
            <Button
                label="Verifier un autre document"
                onClick={refresh}
            />
        </div>
    );
}
