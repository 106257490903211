import "./style.scss";

import { FC, useContext } from "react";
import { updateNotification, updateNotificationSingle } from "../../api/notifications";

import { DocumentsContext } from "../../contexts/DocumentsContext";
import { ReactComponent as IconNotification } from "../../assets/img/icons/icon_bell.svg";
import Notification from "../Notification/Notification";
import { NotificationType } from "../../types/types";
import { UserContext } from "../../contexts/UserContext";

interface NotificationsListProps {
    title?: string;
    fromDashBoard?: boolean;
    filter?: string;
}

const NotificationsList: FC<NotificationsListProps> = ({ title, filter, fromDashBoard = true }) => {
    const { notificationsUnseen, notifications, refreshDocuments } = useContext(DocumentsContext);

    const { userType } = useContext(UserContext);

    const tryUpdateNotificationSingle = async (idNotif: number) => {
        try {
            await updateNotificationSingle(userType, idNotif);
        } catch (error) {
            console.log(error);
        } finally {
            refreshDocuments();
        }
    };

    const updateNotificationsList = async () => {
        try {
            await updateNotification(userType);
        } catch (error) {
            console.log(error);
        } finally {
            refreshDocuments();
        }
    };

    function yourFilteringLogic(notification: NotificationType, filter: string) {
        if (filter === "folder") {
            return notification.action === "FOLDER_NOTIFICATION";
        } else {
            return notification.action !== "FOLDER_NOTIFICATION";
        }
    }

    return (
        <>
            {fromDashBoard && (
                <div className="notifications__header">
                    <p className="notifications__header__title">{title ? title : "Notifications"}</p>
                    <div className="notifications__header__count">
                        <IconNotification
                            fill="#1956A8"
                            width={24}
                            height={24}
                        />
                        <span className="notificationDot-L">{notificationsUnseen}</span>
                    </div>
                </div>
            )}

            <ul className="notifications">
                {/* {notifications.length > 0 && notifications?.map((notification) => (
          <Notification
            key={notification.id}
            notification={notification}
            updateNotification={tryUpdateNotificationSingle}
          />
        ))} */}
                <div>
                    {notifications &&
                        notifications?.length > 0 &&
                        notifications
                            ?.filter(
                                (notification) =>
                                    // Include all items if filter doesn't exist or your filtering logic
                                    !filter || yourFilteringLogic(notification, filter),
                            )
                            .map((notification) => (
                                <Notification
                                    key={notification.id}
                                    notification={notification}
                                    updateNotification={tryUpdateNotificationSingle}
                                />
                            ))}
                </div>
            </ul>
            {notificationsUnseen > 0 && (
                <p
                    className="notifications__button"
                    onClick={updateNotificationsList}>
                    Tout marquer comme lu
                </p>
            )}
        </>
    );
};

export default NotificationsList;
