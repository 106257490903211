import { FolderType, PartnerType, ProceduresType } from "../types/types";
import React, { ReactNode, createContext, useContext, useEffect, useState } from "react";
import {
    addFileToFolder,
    changeInvitationDate,
    changeStateProcedure,
    changeVisibility,
    createFolder,
    deleteFile,
    deleteFolder,
    getFolder,
    getFolders,
    removeFolderPermission,
    sendInvitation,
} from "../api/folder";

import { DocumentsContext } from "./DocumentsContext";
import { PDFDocument } from "pdf-lib";
import apiInstance from "../axiosConfig";
import { toast } from "react-toastify";
import watermark from "../assets/img/badgeBlue.png";
import watermarkPDF from "../assets/img/watermark_1.png";

type FolderContextValue = {
    folders: FolderType[];
    folder: FolderType | undefined;
    listItems: ProceduresType[];
    partners: PartnerType[];
    sharedFolders: FolderType[];
    allFolders: () => Promise<void>;
    tryRemoveFolder: (id: number) => Promise<boolean>;
    tryCreateFolder: (causeOfFraud: string) => Promise<void>;
    tryRemoveFile: (folderId: number, documentId: number | undefined) => Promise<boolean>;
    tryGetFolder: (type: string | undefined, id: string | undefined) => Promise<void>;
    tryChangeVisibility: (folderId: number | undefined) => Promise<void>;
    tryAddFileToFolder: (type: string | undefined, id: string | undefined, file: any, fileType: string) => Promise<void>;
    trySendInvitation: (folderId: number | undefined, email: string, expireDate: string) => Promise<boolean>;
    tryHandleProcedureDone: (procedureId: number, folderId: number | undefined) => Promise<void>;
    tryRemoveFolderPermission: (folderId: number | undefined, userId: number) => Promise<boolean>;
    tryChangeInvitationDate: (authorisationId: number, expireDate: number) => Promise<void>;
    tryAddFilterToFile: (folderId: number, type: string | undefined, file: any, canvas: HTMLCanvasElement | null) => Promise<void>;
    tryAddFilterToFolder: (folderId: number, type: string | undefined, canvas: HTMLCanvasElement | null) => Promise<void>;
};

const FolderContext = createContext<FolderContextValue>({
    folders: [],
    folder: undefined,
    listItems: [],
    partners: [],
    sharedFolders: [],
    allFolders: async () => {},
    tryRemoveFolder: async () => false || true,
    tryCreateFolder: async () => {},
    tryRemoveFile: async () => false || true,
    tryGetFolder: async () => {},
    tryChangeVisibility: async () => {},
    tryAddFileToFolder: async () => {},
    trySendInvitation: async () => false || true,
    tryHandleProcedureDone: async () => {},
    tryRemoveFolderPermission: async () => false || true,
    tryChangeInvitationDate: async () => {},
    tryAddFilterToFile: async () => {},
    tryAddFilterToFolder: async () => {},
});

const FolderContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [folders, setFolders] = useState<FolderType[]>([]);
    const [folder, setFolder] = useState<FolderType>();
    const [listItems, setListItems] = useState<ProceduresType[]>([]);
    const [partners, setPartners] = useState<PartnerType[]>([]);
    const [sharedFolders, setSharedFolders] = useState<FolderType[]>([]);
    const { refreshDocuments } = useContext(DocumentsContext);
    const baseUrl = apiInstance.getUri();
    const userType = localStorage.getItem("type");
    const allFolders = async () => {
        try {
            const folders = await getFolders(userType);
            setFolders(folders.folders);
            setSharedFolders(folders.sharedFolders);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (!userType) return;
        allFolders();
    }, [userType]);

    const tryCreateFolder = async (causeOfFraud: string) => {
        const response = await createFolder(userType, causeOfFraud);
        if (response?.status === 201) {
            const folders = await getFolders(userType);
            setFolders(folders.folders);
            toast.info("Votre dossier a bien été créé !");
            refreshDocuments();
        } else {
            toast.error("Il y a eu un problème...");
        }
    };

    const tryRemoveFolder = async (id: number) => {
        const response = await deleteFolder(userType, id);
        if (response?.status === 200) {
            setFolders(folders.filter((folder) => folder.id !== id));
            refreshDocuments();
            return true;
        } else {
            return false;
        }
    };

    const tryRemoveFile = async (folderId: number, documentId: number | undefined) => {
        const response = await deleteFile(userType, folderId, documentId);
        if (response?.status === 200) {
            setFolder((prevFolder) => {
                if (!prevFolder) return prevFolder;

                return {
                    ...prevFolder,
                    documents: prevFolder.documents.filter((doc) => doc.id !== documentId),
                };
            });
            setFolders((prevFolders) =>
                prevFolders.map((folder) =>
                    folder.id === folderId
                        ? {
                              ...folder,
                              documents: folder.documents.filter((document) => document.id !== documentId),
                          }
                        : folder,
                ),
            );
            refreshDocuments();
            return true;
        } else {
            return false;
        }
    };

    const tryGetFolder = async (type: string | undefined, id: string | undefined) => {
        const result = await getFolder(type, id);
        setFolder(result?.folder);
        setListItems(result?.listItems);
        setPartners(result?.partners);
    };

    const tryChangeVisibility = async (folderId: number | undefined) => {
        try {
            setFolder((prevFolder) => {
                if (!prevFolder) return prevFolder;

                return {
                    ...prevFolder,
                    visibility: !prevFolder.visibility,
                };
            });
            setFolders((prevFolders) =>
                prevFolders.map((folder) => (folder.id === folderId ? { ...folder, visibility: !folder.visibility } : folder)),
            );
            toast.info("Votre dossier a été modifié");
            await changeVisibility(userType, folderId);
            refreshDocuments();
        } catch (error) {
            console.log(error);
        }
    };

    const tryAddFileToFolder = async (type: string | undefined, id: string | undefined, file: any, fileType: string) => {
        const response = await addFileToFolder(type, id, file, fileType);
        setFolders((prevFolders) =>
            prevFolders.map((folder) =>
                folder.id === response.folderUpdated.id ? { ...folder, documents: [...response.folderUpdated.documents] } : folder,
            ),
        );
        refreshDocuments();
    };

    const trySendInvitation = async (folderId: number | undefined, email: string, expireDate: string) => {
        const response = await sendInvitation(userType, folderId, email, Number(expireDate));
        if (response?.status === 200) {
            const resp = await getFolder(userType, folderId);
            setPartners(resp.partners);

            setFolders((prevFolders) =>
                prevFolders.map((folder) => (folder.id === folderId ? { ...folder, authorizations: resp.partners } : folder)),
            );
            refreshDocuments();
            return true;
        } else {
            return false;
        }
    };

    const tryHandleProcedureDone = async (procedureId: number, folderId: number | undefined) => {
        const response = await changeStateProcedure(procedureId, userType);
        setListItems(response?.data.listItems);
        setFolders((prevFolders) =>
            prevFolders.map((folder) => (folder.id === folderId ? { ...folder, items: response?.data.listItems } : folder)),
        );
    };

    const tryRemoveFolderPermission = async (folderId: number | undefined, userId: number) => {
        const response = await removeFolderPermission(userType, folderId, userId);
        if (response?.status === 200) {
            setPartners(partners.filter((partner) => partner.id !== userId));
            setFolders((prevFolders) =>
                prevFolders.map((folder) =>
                    folder.id === folderId
                        ? {
                              ...folder,
                              authorizations: folder.authorizations.filter((authorization) => authorization.clientId !== userId),
                          }
                        : folder,
                ),
            );
            refreshDocuments();
            return true;
        } else {
            return false;
        }
    };

    const tryChangeInvitationDate = async (authorisationId: number, expireDate: number) => {
        const response = await changeInvitationDate(userType, authorisationId, expireDate);

        setPartners((prevPartners) =>
            prevPartners.map((prevPartner) =>
                prevPartner.authorizationId === authorisationId
                    ? {
                          ...prevPartner,
                          authorizationId: response?.data.authorization.id,
                      }
                    : prevPartner,
            ),
        );
    };
    const loadImage = async (file: File | string): Promise<HTMLImageElement> => {
        const image = new Image();
        await new Promise((resolve, reject) => {
            image.onload = resolve;
            image.onerror = reject;
            if (typeof file === "string") {
                image.src = file;
            } else {
                const reader = new FileReader();

                reader.onload = (event) => {
                    image.src = event.target?.result as string;
                };
                reader.onerror = (error) => reject(error);
                reader.readAsDataURL(file);
            }
        });
        return image;
    };

    const tryAddFilterToFile = async (folderId: number, type: string | undefined, file: any, canvas: HTMLCanvasElement | null) => {
        const res = await fetch(`${baseUrl}/client/getfile/${file.path}`);
        const clonedResponse = res.clone();

        // Use clonedResponse for image manipulation
        const blob = await clonedResponse.blob();

        // Use original response for PDF manipulation
        const existingPdfBytes = await res.arrayBuffer();

        const fileExtension = file.name.split(".").pop();
        let finalFile = file;
        if (canvas) {
            const context = canvas?.getContext("2d");

            if (context) {
                if (fileExtension === "jpg" || fileExtension === "png") {
                    const newFile = new File([blob], file.name, { type: blob.type });
                    const image = await loadImage(newFile);

                    // Draw the image on the canvas
                    canvas.width = image.width;
                    canvas.height = image.height;
                    context?.drawImage(image, 0, 0);

                    // Add the watermark image (adjust position and opacity as needed)
                    const watermarkImage = await loadImage(watermark);
                    const watermarkSize = Math.min(canvas.width, canvas.height) * 1;
                    const watermarkX = (canvas.width - watermarkSize) / 2;
                    const watermarkY = (canvas.height - watermarkSize) / 2;
                    context.drawImage(watermarkImage, watermarkX, watermarkY, watermarkSize, watermarkSize);

                    // Convert canvas to blob
                    const newBlob: any = await new Promise((resolve) => {
                        canvas.toBlob((newBlob) => resolve(newBlob), "image/png");
                    });

                    finalFile = new File([newBlob], `${file.name}.png`, { type: "image/png" });
                } else if (fileExtension === "pdf") {
                    // For PDFs (using pdf-lib)
                    const pdfDoc = await PDFDocument.load(existingPdfBytes);
                    const watermarkImageBytes = await fetch(watermarkPDF).then((res) => res.arrayBuffer());
                    const watermarkImage = await pdfDoc.embedPng(watermarkImageBytes);

                    const pages = pdfDoc.getPages();
                    for (const page of pages) {
                        const { width, height } = page.getSize();
                        page.drawImage(watermarkImage, { x: 0, y: 0, width, height });
                    }

                    const pdfBytes = await pdfDoc.save();
                    finalFile = new File([pdfBytes], `${file.name}.pdf`, { type: "application/pdf" });
                }
            }
        }
        await tryAddFileToFolder(type, folderId.toString(), finalFile, "VERBAL");
        await tryRemoveFile(folderId, file.id);
    };

    // apply tryAddFilterToFile to all files in a folder
    const tryAddFilterToFolder = async (folderId: number, type: string | undefined, canvas: HTMLCanvasElement | null) => {
        // get the files in the folder
        const result = await getFolder(type, folderId);
        const files = result.folder.documents;

        for (const file of files) {
            await tryAddFilterToFile(folderId, type, file, canvas);
        }
    };

    return (
        <FolderContext.Provider
            value={{
                folders,
                allFolders,
                tryCreateFolder,
                tryRemoveFolder,
                tryRemoveFile,
                tryGetFolder,
                folder,
                tryChangeVisibility,
                tryAddFileToFolder,
                listItems,
                trySendInvitation,
                tryHandleProcedureDone,
                partners,
                tryRemoveFolderPermission,
                sharedFolders,
                tryChangeInvitationDate,
                tryAddFilterToFile,
                tryAddFilterToFolder,
            }}>
            {children}
        </FolderContext.Provider>
    );
};

export { FolderContext, FolderContextProvider };
