import "./style.scss";

import { Button_Style, Button_Type } from "../Button/Button";
import { FC, useContext } from "react";

import Alert from "../Alert";
import Button from "../Button";
import DocumentError from "../../assets/img/document_warning.svg";
import { ReactComponent as IconEye } from "../../assets/img/icons/icon_status-View.svg";
import { ReactComponent as IconWarning } from "../../assets/img/icons/icon_status-Warning.svg";
import { UserContext } from "../../contexts/UserContext";
import { createFile } from "../../api/analysis";

type ErrorScanProps = {
    error: string;
    verifyReportURL?: string;
    resetState?: () => void;
};
const ErrorScan: FC<ErrorScanProps> = ({ error, verifyReportURL, resetState }) => {
    const { user } = useContext(UserContext);
    const type = user?.type;

    const visualiseFile = async () => {
        if (!verifyReportURL) return;
        try {
            const createFileResponse = await createFile(verifyReportURL);
            const file = createFileResponse.data;

            openPDFInNewTab(file.data, file.size, file.name);
        } catch (error: any) {
            console.log(error.response.data);
        }
    };

    const openPDFInNewTab = (base64Data: string, size: number, name: string) => {
        // Convert base64 to ArrayBuffer
        const binaryString = atob(base64Data);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);

        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }

        // Create a blob from ArrayBuffer
        const blob = new Blob([bytes.buffer], { type: "application/pdf" });

        // Create an Object URL from the blob
        const url = URL.createObjectURL(blob);

        // Open the Object URL in a new tab
        const newWindow = window.open(url, "_blank");

        // Optionally, you can set the new tab's title
        if (newWindow) {
            newWindow.document.title = name;
        }

        // Release the Object URL after use
        setTimeout(() => {
            URL.revokeObjectURL(url);
        }, 1000);
    };
    return (
        <div className="ErrorScan card">
            {error === "Document non reconnu." ? (
                <>
                    <Alert type="info">Aucun crédit ne vous a été décompté.</Alert>
                    <p className="mb-1">Le document n'a pas pu être lu. Veuillez verifier les points suivants :</p>
                    <div className="DocumentStatus">
                        <IconEye
                            fill="#FF8833"
                            width="1.5rem"
                        />{" "}
                        <span>Il s’agit bien d’un document d’identité</span>
                    </div>
                    <div className="DocumentStatus">
                        <IconEye
                            fill="#FF8833"
                            width="1.5rem"
                        />{" "}
                        <span>Les informations sont lisibles</span>
                    </div>
                    <div className="DocumentStatus">
                        <IconEye
                            fill="#FF8833"
                            width="1.5rem"
                        />{" "}
                        <span>Seul le document est visible sur la photo.</span>
                    </div>
                </>
            ) : (
                <>
                    <img
                        src={DocumentError}
                        alt="ID Protect - Document non conforme"
                    />
                    <h5 className="DocumentDetails__body__title">Statut du document</h5>
                    <div className="DocumentStatus">
                        <IconWarning
                            fill="#FF5326"
                            width="1.5rem"
                        />{" "}
                        <span>Le document n'est pas conforme. Veuillez présenter un autre document.</span>
                    </div>
                </>
            )}
            {type === "partner" && (
                <Button
                    label="Rapport ID Expert"
                    type={Button_Type.SECONDARY}
                    style={Button_Style.OUTLINED}
                    className="m-auto mb-1"
                    icon={<IconEye fill="red" />}
                    onClick={() => visualiseFile()}
                    disabled={!verifyReportURL}
                    isLoader={!verifyReportURL}
                />
            )}
            <Button
                label="Recommencer"
                type={Button_Type.PRIMARY}
                className="m-auto"
                onClick={() => resetState && resetState()}
            />
        </div>
    );
};

export default ErrorScan;
