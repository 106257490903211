import { CREDITS_PRICING, ID_TRACKER_PRICING } from "../constants/pricings";
import { FC, useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import DocumentIllustration from "../components/DocumentIllustration";
import ResultAfterPayment from "../components/ResultAfterPayment";
import StripePaymentCard from "../components/StripePaymentCard";
import { UserContext } from "../contexts/UserContext";

const StripePayment: FC = () => {
    const navigate = useNavigate();

    const { offer, planIndex, isMonth } = useParams();

    const { user } = useContext(UserContext);
    const [message, setMessage] = useState("");
    const [title, setTitle] = useState("");
    const [paymentSucceeded, setPaymentSucceeded] = useState(false);

    const [datas, setDatas] = useState<any>({
        user: user,
        addresse: "",
        postalCode: "",
        city: "",
        comment: "",
        perteId: "",
        documents: [],
        pv: [],
        chosenPlan: offer === "id-tracker" ? ID_TRACKER_PRICING[parseInt(planIndex || "0")] : CREDITS_PRICING[parseInt(planIndex || "0")],
    });

    const [steps, setSteps] = useState([
        { isCurrent: true, isDone: false },
        { isCurrent: false, isDone: false },
    ]);

    const handleUpdateStep = (step: number) => {
        setSteps(
            steps.map((s, index) => {
                if (index === step) {
                    return { isCurrent: true, isDone: false };
                } else if (index < step) {
                    return { isCurrent: false, isDone: true };
                }
                return { isCurrent: false, isDone: false };
            }),
        );
    };

    const handleNextStep = () => {
        const currentStep = steps.findIndex((step) => step.isCurrent);
        if (currentStep < steps.length - 1) {
            handleUpdateStep(currentStep + 1);
        }
    };

    const handlePreviousStep = () => {
        const currentStep = steps.findIndex((step) => step.isCurrent);
        if (currentStep > 0) {
            handleUpdateStep(currentStep - 1);
        }
    };

    const handlePaymentResult = (title: string, message: string, paymentSucceeded: boolean) => {
        handleNextStep();
        setTitle(title);
        setMessage(message);
        setPaymentSucceeded(paymentSucceeded);
    };

    return (
        <div className="StripePayment h-100">
            <div className="content__body h-100">
                <div className="row">
                    <div className={`col-sm-6`}>
                        <div className="content--card">
                            {steps[0].isCurrent && (
                                <StripePaymentCard
                                    isMonth={isMonth === "month" ? true : isMonth === "year" ? false : undefined}
                                    title={datas.chosenPlan.title}
                                    link={`particuliers/profil`}
                                    setDatas={setDatas}
                                    datas={datas}
                                    handlePreviousStep={() => {
                                        navigate(-1);
                                    }}
                                    handlePaymentResult={handlePaymentResult}
                                />
                            )}
                            {steps[1].isCurrent && (
                                <ResultAfterPayment
                                    title={title}
                                    message={message}
                                    paymentSucceeded={paymentSucceeded}
                                    steps={steps}
                                    handlePreviousStep={handlePreviousStep}
                                    handleNextStep={ ()=> (window.location.href = "/particuliers/profil")}

                                />
                            )}
                        </div>
                    </div>
                    <div className={`col-sm-5 center`}>
                        <div>
                            <DocumentIllustration type={"payment"} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default StripePayment;
