import "./style.scss";

import { FC, useState } from "react";
import { LinkAuthenticationElement, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { createCustomer, createSubscription, sendEmailAfterPayment, sendErrorPayment } from "../../api/payment";

import Button from "../Button";
import { Button_Style } from "../Button/Button";
import MoneyDisplay from "../MoneyDisplay";
import { PaymentTypes } from "../../constants/pricings";
import { extractNumber } from "../../utils/utils";
import { useNavigate } from "react-router-dom";

interface PaymentStripFormProps {
    title: any;
    steps?: any;
    link: any;
    setDatas: (data: any) => void;
    datas: any;
    handlePreviousStep: () => void;
    handlePaymentResult: (title: string, message: string, paymentSucceeded: boolean) => void;
    isMonth?: boolean;
}

const PaymentStripForm: FC<PaymentStripFormProps> = ({
    title,
    setDatas,
    datas,
    steps,
    link,
    isMonth,
    handlePreviousStep,
    handlePaymentResult,
}) => {
    const navigate = useNavigate();

    const stripe = useStripe();
    const elements = useElements();

    const [isProcessing, setIsProcessing] = useState(false);
    const [textProcessing, setTextProcessing] = useState("Chargement...");
    const [email, setEmail] = useState("");

    let linkAuthenticationElement = elements?.getElement("linkAuthentication");

    linkAuthenticationElement?.on("change", (event) => {});

    const handleSubmit = async (e: any) => {
        setIsProcessing(true);
        e.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        if (!email) {
            setIsProcessing(false);
            return;
        }

        const body = new FormData();
        body.append('type', datas?.user?.type);

        body.append('email', email)

        // Append additional PaymentLog-related from database fields
        body.append("title", datas.chosenPlan?.title ?? "Untitled");
        body.append("head", datas?.chosenPlan?.head ?? "No head");
        body.append("text", datas?.chosenPlan?.text ?? "");
        body.append("isRecurringPayment", datas?.chosenPlan?.isRecurringPayment?.toString() ?? "false");
        body.append("priceIdForMonth", datas?.chosenPlan?.priceIdForMonth ?? "");
        body.append("priceIdForYear", datas?.chosenPlan?.priceIdForYear ?? "");

        if (datas.chosenPlan?.head === PaymentTypes.CREDIT) {
            // On extrait le nombre de credits acheté
            body.append("credits", extractNumber(datas.chosenPlan?.title)!.toString());
        } else if (datas.chosenPlan?.head === PaymentTypes.ACCOMAPGNEMENT) {
            body.append("description", datas?.chosenPlan?.description ?? "");
            body.append("accompagnementType", datas.chosenPlan?.title);
        }

        if (datas.chosenPlan?.head === PaymentTypes.ID_TRACKER) {
            body.append("subscriptionType", datas.chosenPlan?.title);
            body.append("offreId", datas.chosenPlan?.offreId);
            body.append("typePrelevement", `${isMonth ? "mensuel" : "annuel"}`);
        }

        console.log("Recurring payment");

        const { error, paymentIntent } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${window.location.origin}/${link}`,
            },
            redirect: "if_required",
        });

        let nextTitle = `souscription annulée`;
        let message = `Le paiement a été annulé`;
        let isOK = false;

        if (paymentIntent && paymentIntent.status === "succeeded") {
            if (datas?.chosenPlan?.isRecurringPayment) {
                // Create subscription
                setTextProcessing("Souscription...");
                await handleSubmitSubscription(body, paymentIntent.payment_method);
            }

            body.append("amount", (paymentIntent?.amount! * 0.01).toString());
            body.append("transactionId", paymentIntent?.id);

            await sendEmailAfterPayment(body, datas.chosenPlan?.head);

            nextTitle = `PAIEMENT ACCEPTÉ`;
            message = `MERCI d’avoir souscrit.\n Vous bénéficiez de : ${title}`;
            isOK = true;
        } else if (error) {
            console.log(error);
            if (error.type === "validation_error") {
                setIsProcessing(false);
                return;
            }
            nextTitle = `PAIEMENT ANNULÉ`;
            message = `Le paiement a été annulé`;
            isOK = false;

            await sendErrorPayment(body, datas.chosenPlan?.head);
        } else {
            nextTitle = `PAIEMENT ANNULÉ`;
            message = `Le paiement a été annulé`;
            isOK = false;
            await sendErrorPayment(body, datas.chosenPlan?.head);
        }

        setIsProcessing(false);
        handlePaymentResult(nextTitle, message, isOK);
    };

    const handleSubmitSubscription = async (body: any, paymentMethodId: any) => {
        if (paymentMethodId) {
            body.append("paymentMethodId", paymentMethodId);
            const customerId = (await createCustomer(body))?.data.customerId;
            body.append('customerId', customerId); // Stripe customer ID
            body.append('priceId', isMonth != false ?
                datas?.chosenPlan?.priceIdForMonth
                : datas?.chosenPlan?.priceIdForYear
            );
            console.log((await createSubscription(body)));
        }
    };

    return (
        <div
            id="payment-form"
            className="PaymentStripForm w-100 d-flex flex-column align-items-center justify-content-between">
            <div className="w-100 text-center">
                <h2>{title}</h2>
            </div>
            <MoneyDisplay
                plan={datas.chosenPlan}
                isMonth={isMonth}
            />
            <div className="h-stripe">
                <LinkAuthenticationElement
                    options={{ defaultValues: { email: datas?.user?.email ?? "" } }}
                    onChange={(event) => {
                        setEmail(event.value.email);
                    }}
                />
                {/*<AddressElement  options={{mode: "shipping", display: {name: 'full'}, validation: {} }}/>*/}
                <PaymentElement />
            </div>
            <br />
            <div className={`d-flex w-100 justify-content-between flex-wrap`}>
                {steps && (
                    <Button
                        label={"Retour"}
                        onClick={() => handlePreviousStep()}
                        style={Button_Style.OUTLINED}
                    />
                )}

                {!steps && (
                    <Button
                        label={"Retour"}
                        onClick={(e) => {
                            e.preventDefault();
                            navigate(-1);
                        }}
                        style={Button_Style.OUTLINED}
                    />
                )}

                <Button
                    disabled={isProcessing}
                    onClick={handleSubmit}
                    label={`${isProcessing ? textProcessing : "PAYER"}`}
                    data-testid="payer-stripe-button"
                />
            </div>
        </div>
    );
};

export default PaymentStripForm;
